body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollcontainer {
    max-height: 380px;
    overflow-y: scroll;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #dfdada #fff; /* For Firefox */
}

/* For WebKit-based browsers (Chrome, Edge, Safari) */
.scrollcontainer::-webkit-scrollbar {
    width: 1px;
}

.scrollcontainer::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 1px;
}

.scrollcontainer::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.scrollcontainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}
